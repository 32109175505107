/* eslint-disable eqeqeq , no-useless-escape , react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import { changeFieldsWithPrefixAndCamelCase, getApiRoot, getContextRoot, getLanguageValue, translateCardDeclineReason, googleRecaptcha, getCountriesList } from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import GenericFieldComponent from '../GenericFieldComponent';
import CONSTANTS from '../../common/constants';
import { CSS_COLORS } from '../../common/cssColors';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Loader from "../Loader";
import {emptyValueValidator, numericValueValidator, stringValueValidator} from "../../common/validators";
import { reportPurchase } from "../../common/googleReporter";

let stripePromise;
let stripePromiseInterval = setInterval(() => {
    if (CONSTANTS.STRIPE_KEY != null) {
        clearInterval(stripePromiseInterval);
        stripePromise = loadStripe(CONSTANTS.STRIPE_KEY);
    }
}, 100);

let PAYPAL_BUTTONS_REF = null;
let PAYPAL_ACTIONS_REF;
let GLOBAL_VALIDATE_REF = null;
let GLOBAL_DISPATCH_REF = null;
let GLOBAL_LOADERS_REF = null;
let GLOBAL_SET_LOADERS_REF = null;
let GLOBAL_SET_ERROR_MESSAGE = null;

function RegisterStagePayComponent(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    useEffect(() => {
        actions.setActiveStage(7);
    }, []);

    useEffect(() => {
        // window.addEventListener('popstate', onBackButtonEvent);
        // return () => {
        //     window.removeEventListener('popstate', onBackButtonEvent);
        // }
        // return history.listen(location => {
        //     if (history.action === 'PUSH') {
        //         console.log('PUSH', location.key);
        //         setLocationKeys([ location.key ]);
        //     }
        //
        //     if (history.action === 'POP') {
        //         if (locationKeys[1] === location.key) {
        //             setLocationKeys(([ _, ...keys ]) => keys);
        //             console.log('POP forward');
        //             // Handle forward event
        //
        //         } else {
        //             setLocationKeys((keys) => [ location.key, ...keys ]);
        //             console.log('POP back');
        //             // Handle back event
        //
        //         }
        //     }
        // })
    }, []);

    if (!props.individualKey) {
        return null;
    }

    return <Elements stripe={stripePromise}>
            <SubComponent individualKey={props.individualKey} languageKey={props.languageKey} />
        </Elements>;

    function SubComponent(props) {

        const [ paymentType, setPaymentType ] = useState('paypal');
        const [ fieldsData, setFieldsData ] = useState({
            fullName: '',
            addressLine1: '',
            addressLine2: '',
            country: props.languageKey != 'he' ? 'select' : 'Israel',
            state: '',
            city: '',
            zipCode: '',
            // addressSameAsBilling: false,
            // billingAddressLine1: '',
            // billingAddressLine2: '',
            // billingAddressCountry: 'Israel',
            // billingAddressState: '',
            // billingAddressCity: '',
            // billingAddressZipCode: ''
        });
        const [ payButtonDisabled, setPayButtonDisabled ] = useState(false);
        let [ fieldErrors, setFieldErrors ] = useState({ });

        const FIELDS_VALIDATORS = [
            {
                key: 'fullName',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'addressLine1',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'allAndNumeric' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'addressLine2',
                validators: [
                    {
                        test: (value) => {
                            if (value.length) {
                                if (!/^([\u0590-\u05FF\"\'\.\-,\s0-9]+)$/g.test(value) && !/^([a-zA-Z\"\'\.\-,\s0-9]+)$/g.test(value)) {
                                    return false;
                                }
                            }
                            return true;
                        },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'city',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'state',
                validators: [
                    {
                        test: (value) => { if (value.length) { if (!/^([\u0590-\u05FF\s]+)$/g.test(value) && !/^([a-zA-Z\s]+)$/g.test(value)) return false; } return true; },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'country',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: stringValueValidator.bind({ languageKey: 'all' }),
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            {
                key: 'zipCode',
                validators: [
                    {
                        test: emptyValueValidator,
                        errorMessage: getLanguageValue('register-field-errors-empty')
                    },
                    {
                        test: (value) => { return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                        errorMessage: getLanguageValue('register-field-errors-invalid')
                    }
                ]
            },
            // {
            //     key: 'billingAddressLine1',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressLine2',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ?
            //                 returnTrue :
            //                 (value) => {
            //                     if (value.length) {
            //                         if (!/^([\u0590-\u05FF\s0-9]+)$/g.test(value) || !/^([a-zA-Z\s0-9]+)$/g.test(value)) {
            //                             return false;
            //                         }
            //                     }
            //                     return true;
            //             },
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressCity',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressCountry',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressState',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : stringValueValidator.bind({ languageKey: 'all' }),
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // },
            // {
            //     key: 'billingAddressZipCode',
            //     validators: [
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : emptyValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-empty')
            //         },
            //         {
            //             test: fieldsData.addressSameAsBilling ? returnTrue : numericValueValidator,
            //             errorMessage: getLanguageValue('register-field-errors-invalid')
            //         }
            //     ]
            // }
        ];
        let [ cardFieldError, setCardFieldError ] = useState({ });
        const [ cardComplete, setCardComplete ] = useState({ });
        const [ errorMessage, setErrorMessage] = useState('');
        const [ loaders, setLoaders ] = useState({ });

        const stripe = useStripe();
        const elements = useElements();

        GLOBAL_VALIDATE_REF = validate;
        GLOBAL_DISPATCH_REF = dispatch;
        GLOBAL_LOADERS_REF = loaders;
        GLOBAL_SET_LOADERS_REF = setLoaders;
        GLOBAL_SET_ERROR_MESSAGE = setErrorMessage;

        useEffect(() => {
            window.routeChangeTimestamp = new Date().getTime();
            return () => {
                if (window.paypal) {
                    if (PAYPAL_BUTTONS_REF) {
                        if (PAYPAL_BUTTONS_REF.close) {
                            PAYPAL_BUTTONS_REF.close();
                        }
                        PAYPAL_BUTTONS_REF = null;
                    }
                }
            };
        }, []);

        useEffect(() => {
            setTimeout(() => {
                if (window.paypal) {
                    if (PAYPAL_BUTTONS_REF) {
                        if (PAYPAL_BUTTONS_REF.close) {
                            PAYPAL_BUTTONS_REF.close();
                        }
                        PAYPAL_BUTTONS_REF = null;
                    }
                }
            }, 10);

            if (stripe && elements && window.paypal) {
                //!PAYPAL_INITIALIZED_FLAG
                //PAYPAL_INITIALIZED_FLAG = true;
                setTimeout(() => {

                    PAYPAL_BUTTONS_REF = window.paypal.Buttons({
                        onInit: function(data, actions) {
                            PAYPAL_ACTIONS_REF = actions;
                            actions.disable();
                        },
                        onClick: function() {
                            GLOBAL_VALIDATE_REF({ setErrors: true });
                        },
                        createOrder: async function() {
                            let duplicateData = { ...fieldsData };
                            //duplicateData.addressSameAsBilling = duplicateData.addressSameAsBilling ? '1' : '0';
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: true });
                            let recaptchaToken = await googleRecaptcha();
                            return fetch(getApiRoot() + 'init-payment/?type=individual&paymentType=paypal&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp), {
                                method: 'post',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey,
                                    ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stagePay')
                                })
                            }).then(function(res) {
                                return res.json();
                            }).then(function(data) {
                                return data.data.clientSecret;
                            }).catch(function() {
                                GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-network-error'));
                            });
                        },
                        onCancel: function() {
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                        },
                        onApprove: async function() {
                            let recaptchaToken = await googleRecaptcha();
                            return fetch(getApiRoot() + 'complete-payment/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp), {
                                method: 'POST',
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey
                                })
                            }).then(function(res) {
                                return res.json();
                            }).then(function(data) {
                                if (!lodashGet(data, 'data.success')) {
                                    GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                    GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                                    return;
                                }
                                // paypal payment ok
                                reportPurchase(lodashGet(data, 'data.totalPrice'));
                                GLOBAL_DISPATCH_REF({
                                    type: ACTIONS.ROUTER_SET_REDIRECT,
                                    payload: '/register-visa-for-individual/stageSuccess'
                                });
                            }).catch(function() {
                                GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                                GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                            });
                        },
                        onError: (err) => {
                            GLOBAL_SET_LOADERS_REF({ ...GLOBAL_LOADERS_REF, paypal: false });
                            GLOBAL_SET_ERROR_MESSAGE(getLanguageValue('register-card-errors-critical-network-error'));
                        }
                    });
                    PAYPAL_BUTTONS_REF.render('#paypal-button-container');
                }, 60);
            }
        }, [ stripe, elements ]);

        useEffect(() => {
            if (PAYPAL_ACTIONS_REF) {
                const validationResult = validate();
                if (!validationResult.length) {
                    PAYPAL_ACTIONS_REF.enable();
                }
                else {
                    PAYPAL_ACTIONS_REF.disable();
                }
           }
        }, [ fieldsData, paymentType ]);

        return <RegisterStagePayStyle>
            <div className={"form-container"}>
                <h1>{getLanguageValue('register-individual.stagePay-payment-details')}</h1>
                <Grid container>
                <Grid item md={6} className={"column-container"}>
                    <h2>{getLanguageValue('register-individual.stagePay-billing-address-details')}</h2>
                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.fullName,
                            value: fieldsData.fullName,
                            inputProps: {
                                name: 'fullName'
                            },
                            helperText: fieldErrors.fullName || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'fullName' }),
                            label: getLanguageValue('register-individual.stagePay-full-name') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.addressLine1,
                            value: fieldsData.addressLine1,
                            inputProps: {
                                name: 'addressLine1'
                            },
                            helperText: fieldErrors.addressLine1 || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'addressLine1' }),
                            label: getLanguageValue('register-individual.stagePay-address-line1') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.addressLine2,
                            value: fieldsData.addressLine2,
                            inputProps: {
                                name: 'addressLine2'
                            },
                            helperText: fieldErrors.addressLine2 || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'addressLine2' }),
                            label: getLanguageValue('register-individual.stagePay-address-line2')
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"select"}
                        selectId={'country'}
                        selectLabel={getLanguageValue('register-individual.stagePay-country') + ' *'}
                        selectOptions={[
                            { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                            ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                        ]}
                        selectFormClassName={'field-component' + (fieldErrors.country ? ' field-error' : '')}
                        fieldProps={{
                            native: true,
                            error: fieldErrors.country,
                            value: fieldsData.country,
                            inputProps: {
                                name: 'country',
                                id: 'country'
                            },
                            helperText: fieldErrors.country || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'country' })
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.state,
                            value: fieldsData.state,
                            inputProps: {
                                name: 'state'
                            },
                            helperText: fieldErrors.state || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'state' }),
                            label: getLanguageValue('register-individual.stagePay-state')
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.city,
                            value: fieldsData.city,
                            inputProps: {
                                name: 'city'
                            },
                            helperText: fieldErrors.city || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'city' }),
                            label: getLanguageValue('register-individual.stagePay-city') + ' *'
                        }}
                    />

                    <br />

                    <GenericFieldComponent
                        type={"textfield"}
                        fieldProps={{
                            className: 'field-component',
                            error: fieldErrors.zipCode,
                            value: fieldsData.zipCode,
                            inputProps: {
                                name: 'zipCode'
                            },
                            helperText: fieldErrors.zipCode || '',
                            onChange: DEFAULT_ON_CHANGE,
                            onBlur: validateSingleField.bind({ key: 'zipCode' }),
                            label: getLanguageValue('register-individual.stagePay-zip-code') + ' *'
                        }}
                    />
                </Grid>
                <Grid item md={6} className={"column-container"}>
                    <h2>{getLanguageValue('register-individual.stagePay-billing-payment-details')}</h2>
                    <div className={"payment-type-container"}>
                        <div className={"payment-type-inner-container"}>
                            <RadioGroup aria-label="payment-type" name="paymentTypeRadios" value={paymentType} onChange={(e) => { setPaymentType(e.target.value); }}>
                                {/*<FormControlLabel
                                    classes={{ root: 'payment-type-control-root-component', label: 'payment-type-label-control-label-component' }}
                                    value="card"
                                    control={<Radio
                                        disabled={isLoading()}
                                        classes={{ root: 'payment-type-card-radio-root' }}
                                        color="primary"
                                    />}
                                    label={<><span><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/credit-cards.png'} /></span><span className={"payment-type-text card"}>{getLanguageValue('register-individual.stagePay-payment-type-card')}</span></>}
                                />*/}
                                <FormControlLabel
                                    classes={{ root: 'payment-type-control-root-component', label: 'payment-type-label-control-label-component' }}
                                    value="paypal"
                                    control={<Radio
                                        disabled={isLoading()}
                                        classes={{ root: 'payment-type-paypal-radio-root' }}
                                        color="primary"
                                    />}
                                    label={<><span><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/credit-cards.png'} /><img alt={''} className={"payment-type-image"} src={getContextRoot() + 'images/paypal.png'} /></span><span className={"payment-type-text paypal"}></span></>}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                    <div className={"billing-card-details-container" + (paymentType == 'card' ? '' : ' invisible')}>
                        <div className={"card-number-container" + (cardFieldError.cardNumber ? ' field-error' : '')}>
                            <div className={"field-title-wrapper"}><label className={"field-title"}>{getLanguageValue('register-individual.stagePay-card-number')} *</label></div>
                            <CardNumberElement
                                className={"card-number-element" + (cardFieldError.cardNumber ? ' with-error' : '')}
                                onChange={(e) => {
                                    setCardFieldError({ ...cardFieldError, cardNumber: e.error });
                                    setCardComplete({ ...cardComplete, cardNumber: e.complete });
                                }}
                            />
                        </div>
                        <div className={"card-expiry-container" + (cardFieldError.cardExpiry ? ' field-error' : '')}>
                            <div className={"field-title-wrapper"}><label className={"field-title"}>{getLanguageValue('register-individual.stagePay-card-expiry')} *</label></div>
                            <CardExpiryElement
                                className={"card-expiry-element" + (cardFieldError.cardExpiry ? ' with-error' : '')}
                                onChange={(e) => {
                                    setCardFieldError({ ...cardFieldError, cardExpiry: e.error });
                                    setCardComplete({ ...cardComplete, cardExpiry: e.complete });
                                }}
                            />
                        </div>
                        <div className={"card-cvc-container" + (cardFieldError.cardCvc ? ' field-error' : '')}>
                            <div className={"field-title-wrapper"}><label className={"field-title"}>{getLanguageValue('register-individual.stagePay-card-cvc')} *</label></div>
                            <CardCvcElement
                                className={"card-cvc-element" + (cardFieldError.cardCvc ? ' with-error' : '')}
                                onChange={(e) => {
                                    setCardFieldError({ ...cardFieldError, cardCvc: e.error });
                                    setCardComplete({ ...cardComplete, cardCvc: e.complete });
                                }}
                            />
                        </div>
                    </div>
                    <div className={"paypal-billing-details-container" + (paymentType == 'paypal' ? '' : ' invisible')}>
                        <div className={"paypal-billing-details-inner-container"}>
                            <div id="paypal-button-container" />
                            { loaders.paypal ? <>&nbsp; <Loader className="paypal-loader" /></> : ''}
                        </div>
                    </div>
                    <div className={"billing-address-details-container"} style={{ display: 'none' }}>
                        <FormControlLabel
                            className={"address-same-as-billing-component"}
                            control={
                                <Checkbox
                                    checked={fieldsData.addressSameAsBilling}
                                    onChange={(e) => { toggleAddressSameAsBilling(e); }}
                                    name="addressSameAsContact"
                                    color="primary"
                                />
                            }
                            label={getLanguageValue('register-individual.stagePay-address-same-as-payer-address')}
                        />

                        <br />
                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressLine1,
                                value: fieldsData.addressSameAsBilling ? fieldsData.addressLine1 : fieldsData.billingAddressLine1,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressLine1'
                                },
                                helperText: fieldErrors.billingAddressLine1 || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressLine1' }),
                                label: getLanguageValue('register-individual.stagePay-address-line1') + ' *'
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressLine2,
                                value: fieldsData.addressSameAsBilling ? fieldsData.addressLine2 : fieldsData.billingAddressLine2,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressLine2'
                                },
                                helperText: fieldErrors.billingAddressLine2 || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressLine2' }),
                                label: getLanguageValue('register-individual.stagePay-address-line2')
                            }}
                        />

                        <br />

                        <GenericFieldComponent

                            type={"select"}
                            selectId={'billingAddressCountry'}
                            selectLabel={getLanguageValue('register-individual.stagePay-country') + ' *'}
                            selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                            ]}
                            selectFormClassName={'billing-address field-component' + (!fieldsData.addressSameAsBilling && fieldErrors.billingAddressCountry ? ' field-error' : '')}
                            fieldProps={{
                                native: true,
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressCountry,
                                value: fieldsData.addressSameAsBilling ? fieldsData.country : fieldsData.billingAddressCountry,
                                disabled: true,
                                inputProps: {
                                    name: 'country',
                                    id: 'billingAddressCountry'
                                },
                                helperText: fieldErrors.billingAddressCountry || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: (fieldsData.addressSameAsBilling ? 'country' : 'billingAddressCountry') })
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressState,
                                value: fieldsData.addressSameAsBilling ? fieldsData.state : fieldsData.billingAddressState,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressState'
                                },
                                helperText: fieldErrors.billingAddressState || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressState' }),
                                label: getLanguageValue('register-individual.stagePay-state') + ' *'
                            }}
                        />

                        <br />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressCity,
                                value: fieldsData.addressSameAsBilling ? fieldsData.city : fieldsData.billingAddressCity,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressCity'
                                },
                                helperText: fieldErrors.billingAddressCity || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressCity' }),
                                label: getLanguageValue('register-individual.stagePay-city') + ' *'
                            }}
                        />

                        <GenericFieldComponent
                            type={"textfield"}
                            fieldProps={{
                                className: 'billing-address field-component',
                                error: !fieldsData.addressSameAsBilling && fieldErrors.billingAddressZipCode,
                                value: fieldsData.addressSameAsBilling ? fieldsData.zipCode : fieldsData.billingAddressZipCode,
                                disabled: fieldsData.addressSameAsBilling,
                                inputProps: {
                                    name: 'billingAddressZipCode'
                                },
                                helperText: fieldErrors.billingAddressZipCode || '',
                                onChange: DEFAULT_ON_CHANGE,
                                onBlur: validateSingleField.bind({ key: 'billingAddressZipCode' }),
                                label: getLanguageValue('register-individual.stagePay-zip-code') + ' *'
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            </div>
            {paymentType != 'paypal' && <div className="pay-now-button-container">
                <Button disabled={loaders.pay || payButtonDisabled} className={"pay-now-button common-style-primary-button"} onClick={pay}>{getLanguageValue('register-individual.stagePay-pay-button-text')} {loaders.pay ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>
                <div className={"error-container"}>{errorMessage}</div>
            </div>}
        </RegisterStagePayStyle>;

        function DEFAULT_ON_CHANGE(e) {
            setFieldsData({...fieldsData, [e.target.name] : e.target.value });
            setFieldErrors({ ...fieldErrors, [e.target.name] : null });
        }

        function toggleAddressSameAsBilling() {
            fieldsData.addressSameAsBilling = !fieldsData.addressSameAsBilling;
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, billingAddressLine1: false, billingAddressLine2: false, billingAddressCity: false, billingAddressState: false, billingAddressCountry: false, billingAddressZipCode: false });
        }

        async function pay() {
            try {
                if (loaders.pay) {
                    return;
                }
                const validateResult = validate({ setErrors: true });
                if (validateResult.length) {
                    setTimeout(function() {
                        if (document.querySelectorAll('.field-error')[0]) {
                            document.querySelectorAll('.field-error')[0].scrollIntoView();
                        }
                    }, 50);
                    return;
                }
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }
                setLoaders({ ...loaders, pay: true });
                let url = getApiRoot() + 'init-payment/?type=individual&paymentType=card&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let duplicateData = { ...fieldsData };
                //duplicateData.addressSameAsBilling = duplicateData.addressSameAsBilling ? '1' : '0';
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        key: props.individualKey,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stagePay')
                    })
                };
                setErrorMessage('');
                let payApiResult;
                try {
                    payApiResult = await (await fetch(url, options)).json();
                }
                catch (err) {
                    // error on creating payment intent
                    setLoaders({ ...loaders, pay: false });
                    return;
                }
                if (!lodashGet(payApiResult, 'data.clientSecret')) {
                    // error on creating payment intent
                    setErrorMessage(getLanguageValue('register-card-errors-network-error'));
                    setLoaders({ ...loaders, pay: false });
                    return;
                }

                let result = await stripe.confirmCardPayment(lodashGet(payApiResult, 'data.clientSecret'), {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: fieldsData.fullName,
                        },
                    }
                });

                if (result.error) {
                    let declineReasonText = translateCardDeclineReason(result);
                    setErrorMessage(declineReasonText);
                    setLoaders({ ...loaders, pay: false });
                    return;
                }
                else {
                    if (result.paymentIntent.status === 'succeeded') {
                        let completePaymentResult;
                        try {
                            recaptchaToken = await googleRecaptcha();
                            options = {
                                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                                body: JSON.stringify({
                                    recaptchaToken,
                                    key: props.individualKey
                                })
                            };
                            url = getApiRoot() + 'complete-payment/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                            completePaymentResult = await (await fetch(url, options)).json();
                            if (!lodashGet(completePaymentResult, 'data.success')) {
                                setLoaders({ ...loaders, pay: false });
                                setErrorMessage(getLanguageValue('register-card-errors-critical-network-error'));
                                setPayButtonDisabled(true);
                                return;
                            }
                        }
                        catch (err) {
                            // nothing to do
                            setLoaders({ ...loaders, pay: false });
                            setErrorMessage(getLanguageValue('register-card-errors-critical-network-error'));
                            setPayButtonDisabled(true);
                            return;
                        }
                        // visa payment ok
                        reportPurchase(lodashGet(completePaymentResult, 'data.totalPrice'));
                        dispatch({
                            type: ACTIONS.ROUTER_SET_REDIRECT,
                            payload: '/register-visa-for-individual/stageSuccess'
                        });
                    }
                    else {
                        // unknown error
                        setErrorMessage(getLanguageValue('register-card-errors-invalid-generic'));
                    }
                }
                setLoaders({ ...loaders, pay: false });
            }
            catch (err) {
                setLoaders({ ...loaders, pay: false });
            }
        }

        function validate(config) {
            let output = [];
            if (lodashGet(config, 'setErrors')) {
                fieldErrors = {};
                cardFieldError = {};
            }

            for (let fieldToValidate of FIELDS_VALIDATORS) {
                const fieldKey = fieldToValidate.key;
                for (let vLoop = 0 ; vLoop < fieldToValidate.validators.length ; ++vLoop) {
                    const validator = fieldToValidate.validators[vLoop];
                    if (!validator.test(fieldsData[fieldKey])) {
                        output.push(fieldKey);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldKey] = validator.errorMessage;
                        }
                        vLoop = fieldToValidate.validators.length; // no need to check more validators
                    }
                }
            }
            if (paymentType == 'card') {
                if (!cardComplete.cardNumber) {
                    output.push('cardNumber');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardNumber = true;
                    }
                }
                if (!cardComplete.cardExpiry) {
                    output.push('cardExpiry');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardExpiry = true;
                    }
                }
                if (!cardComplete.cardCvc) {
                    output.push('cardCvc');
                    if (lodashGet(config, 'setErrors')) {
                        cardFieldError.cardCvc = true;
                    }
                }
            }

            if (lodashGet(config, 'setErrors')) {
                if (paymentType == 'card') {
                    setCardFieldError(cardFieldError);
                }
                setFieldErrors(fieldErrors);
            }
            return output;
        }

        function validateSingleField() {
            const matchedValidatorObj = FIELDS_VALIDATORS.find((item) => item.key == this.key);
            if (matchedValidatorObj) {
                for (let vLoop = 0 ; vLoop < matchedValidatorObj.validators.length ; ++vLoop) {
                    const validator = matchedValidatorObj.validators[vLoop];
                    if (!validator.test(fieldsData[this.key])) {
                        setFieldErrors({ ...fieldErrors, [this.key]: validator.errorMessage });
                        return;
                    }
                }
            }
        }

        function isLoading() {
            for (let key in loaders) {
                if (loaders[key]) {
                    return true;
                }
            }
            return false;
        }
    }
}

const RegisterStagePayStyle = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: 95%;
    }
  }
  .column-container {
    width: 100%;
  }
  .field-component {
    margin-top: 25px;
    min-width: 270px;
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .payment-type-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      text-align: center !important;
    }
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .payment-type-inner-container {
    display: inline-block;
  }
  .payment-type-control-root-component {
    html.rtl & {
      margin-right: -5px;
    }
  }
  .payment-type-image {
    max-height: 30px;
  }
  .payment-type-text {
    position: relative;
    top: -5px;
    &.card {
      padding-right: 8px;
      html.ltr & {
        padding-right: 0;
        padding-left: 12px;
      }
    }
    &.paypal {
      padding-right: 25px;
      html.ltr & {
        padding-right: 0;
        padding-left: 29px;
      }
    }
  }
  .billing-card-details-container {
    &.invisible {
      display: none;
    }
    html.rtl & {
      text-align: right;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
    html.ltr & {
      text-align: left;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
  }
  .card-number-container {
    margin-top: 48px;
  }
  .field-title {
    display: inline-block;
    margin-bottom: 5px;
    width: 250px;
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .card-number-element, .card-expiry-element, .card-cvc-element {
    display: inline-block;
    border-bottom: 1px solid black;
    min-width: 250px;
    margin-bottom: 25px;
    &.with-error {
      border-width: 2px;
      border-color: red;
    }
  }
  .paypal-billing-details-container {
    &.invisible {
      display: none;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      text-align: center !important;
    }
    html.rtl & {
      text-align: right;
    }
    html.ltr & {
      text-align: left;
    }
  }
  .paypal-billing-details-inner-container {
    display: inline-block;
  }
  .billing-address-details-container {
    html.rtl & {
      text-align: right;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
    html.ltr & {
      text-align: left;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        text-align: center;
      }
    }
  }
  .address-same-as-billing-component {
    html.rtl & {
      margin-right: -10px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        margin-right: -45px;
      }
    }
  }
  .field-component.billing-address {
    html.rtl & {
      margin-left: 15px;
    }
    html.ltr & {
      margin-right: 15px;
    }
  }
  .pay-now-button-container {
    margin-top: 10px;
    text-align: center;
  }
  .pay-now-button {
    width: 170px;
  }
  .error-container {
    margin-top: 15px;
    color: ${CSS_COLORS.FIELD_WARNING};
    font-size: 20px;
  }
`;

const RegisterStagePay = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        stage3Data: lodashGet(state, 'registerIndividualStagesData.stage3Data'),
        stage4Data: lodashGet(state, 'registerIndividualStagesData.stage4Data'),
        stage5Data: lodashGet(state, 'registerIndividualStagesData.stage5Data'),
    }),
    {})(RegisterStagePayComponent);

export default RegisterStagePay;
